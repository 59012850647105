<template>
  <div
    ref="dropFilter"
    class="form-search-filter gap-md-0"
    :class="isMain ? 'gap-15' : 'gap-20'"
  >
    <div
      class="gap-md-0 flex-md-fill"
      :class="[isMain ? 'd-flex flex-md-row gap-15 flex-column order-md-2 order-1' : 'd-md-flex gap-20 order-2 order-md-1 w-md-70', !isMain ? !isShowFilter ? 'd-none' : 'show-filter container' : 'd-block']"
    >
      <SelectLayout
        v-model="searchCity"
        class="form-select icon-mark select-icon"
        :class="isMain ? 'radius-custom shadow-md-none shadow-2' : 'rounded-custom shadow-md-none shadow'"
        :options="[{title: 'Москва', alias: 'moscow', id: 1}, {title: 'Новосибирск', alias: 'novosibirsk', id: 2}]"
        :url-getter="(title) => '/cities?title='+title"
        target="cities"
        :is-filter="false"
        :is-dropdown="true"
        :clearable="false"
      />
      <SelectLayout
        v-model="searchSource"
        class="form-select"
        :class="isMain ? 'radius-custom shadow-md-none shadow-2' : 'rounded-md-0 rounded-20 mt-md-0 mt-10 shadow-md-none shadow'"
        :options="[{title: 'Вакансии', id: SearchSource.Vacancies}, {title: 'Кандидаты', id: SearchSource.Summaries}]"
        :reduce="(val) => val.id"
        :is-filter="false"
        :clearable="false"
        :searchable="false"
      />
    </div>
    <div
      class="form-search-input flex-lg-fill order-1 order-md-2"
      :class="isMain ? 'w-lg-30 w-md-50 w-100 flex-md-row gap-md-0 p-md-7 flex-column p-0 gap-15 bg-transparent' : 'p-0 rounded-20 w-md-90 w-100'"
    >
      <SuggestionsInput
        v-model="searchProfession"
        class="form-control p-0"
        placeholder="Введите название профессии"
        target="professions"
        :class="isMain ? 'bg-md-transparent rounded-md-0 bg-white rounded-20 shadow-md-none shadow-2' : 'bord-none'"
        :url-getter="(title) => '/professions?title='+title"
      />
      <svg
        v-if="!isMain"
        height="50"
        width="50"
        style="cursor: pointer"
        :style="!isShowFilter ? 'fill: #AD00FF;' : 'fill: #FF9820;'"
        class="me-5 my-auto d-md-none d-block"
        @click="isShowFilter = !isShowFilter"
      >
        <use xlink:href="/public/icons/main.svg#page-info" />
      </svg>
      <button
        class="btn btn-accent text-white shadow-none d-flex gap-10 align-items-center justify-content-center pe-20"
        :class="isMain ? 'p-custom rounded-md-40 rounded-20' : 'rounded-md-pill round-btn ps-md-34 ps-10'"
        style="background-image: none"
        type="submit"
        @click="search"
      >
        <span :class="!isMain ? 'd-md-block d-none' : null">Поиск</span>
        <svg
          height="30"
          width="30"
        >
          <use xlink:href="/public/icons/main.svg#search" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import SelectLayout from "~/components/inputs/selectLayout.vue";
import { useClient } from "~/stores/client";
import SuggestionsInput from "~/components/inputs/SuggestionsInput.vue";
import { onClickOutside } from '@vueuse/core'
import {SearchSource} from "~/composables/enums/SearchSource";

const client = useClient();
const route = useRoute();
const router = useRouter();

defineProps({
  isMain: {type: Boolean, default: false}
});
defineEmits(['getCity', 'getSource']);

//-----STATE-----\\
const searchCity = useState<City>('searchCity');
const searchSource = useState<number>('searchSource');
const searchProfession = useState<string>('searchProfession');
// const searchProfession = ref<string>();
const isShowFilter = ref<boolean>(false);
const dropFilter = ref();

onClickOutside(dropFilter, () => isShowFilter.value = false);

//-----FETCH-----\\
if (import.meta.server) {
  const isSearch = route.name === 'search';
  const city = isSearch ? route.params.city : (client.city.alias??'novosibirsk');

  if (isSearch && city === undefined) {
    router.push('/');;
  } else {
    let cityEntity: City | null = null;
    await mainFetch('cities?alias=' + city).then((response) => {
      response = response.data.value;
      cityEntity = response.cities[0];
    });
    if (cityEntity === null) {
      //TODO: Показать ошибку
    }

    let source: number | null = null;
    if (isSearch) {
      // source = Number(route.query.source);
      source = route.params.source === 'vacancies' ? SearchSource.Vacancies : SearchSource.Summaries;
    }
    if (source === null || isNaN(source)) {
      source = client.getActiveCompany === null ? SearchSource.Vacancies : SearchSource.Summaries;
    }

    searchCity.value = cityEntity;
    searchSource.value = source;
  }
}

// searchProfession.value = route.query.profession as string|undefined;
if (route.query.profession !== undefined) {
  searchProfession.value = route.query.profession as string|undefined;
}

//-----METHODS-----\\
function search() {
  isShowFilter.value = false;
  const profession = searchProfession.value?.trim();

  router.push({
    path: '/' + searchCity.value.alias + '/' + (searchSource.value === SearchSource.Vacancies ? 'vacancies' : 'summaries'),
    query: {
      profession: profession === "" ? undefined : profession,
    }
  });
}
</script>
<style scoped lang="scss">
.rounded-custom {
  border-bottom-left-radius: 100%;
  border-top-left-radius: 100%;

  @media (max-width: 767px) {
    border-radius: 20px;
  }
}

@media (max-width: 767px) {
  .round-btn {
    border-radius: 0 20px 20px 0 !important;
  }

  .show-filter {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0 15px;
    top: 178px;
    z-index: 1;
  }

  .p-custom {
    padding: 15px !important;
  }
}
</style>
